<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queryParams['status']" class="mr-2 mb-2" style="width: 128px">
        <b-form-select-option v-for="(option, index) in [0, 1, -1]" :key="index" :value="option">
          {{ $t('user.verification_status.' + option) }}
        </b-form-select-option>
        <b-form-select-option :value="null" class="text-placeholder">All Status</b-form-select-option>
      </b-form-select>

      <b-form-input v-model="queryParams['user_id']" type="search" :placeholder="$t('user.user_id')" style="width: 146px" class="mr-2 mb-2" @keyup.enter="onSearch" />

      <b-form-input v-model="queryParams['email']" type="search" :placeholder="$t('general.email')" style="width: 200px" class="mr-2 mb-2" @keyup.enter="onSearch" />

      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :show-dropdowns="true"
        :ranges="ranges"
        :max-date="maxDate"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px" />

      <button-group :onSearch="onSearch" :onReset="onReset" />
    </div>

    <b-table :fields="fields" :items="items" :busy="isLoading" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #cell(status)="{ value }">
        <UserVerificationStatus :value="value.toString()" />
      </template>
      <template #cell(additional_info)="{ value }">
        <tooltip-span :content="value" />
      </template>
      <template #cell(user)="{ value }">
        <span v-if="value" @click="viewUser(value)" class="action">{{ value.name ? value.name : '-' }}</span>
      </template>
      <template #cell(photo_id)="{ value }">
        <a v-if="value" :href="`${value}`" target="_blank" v-b-tooltip.right="'Click to open in new tab'">
          <b-img-lazy :src="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
        </a>
        <b-badge v-else variant="danger" class="font-weight-bold"> ⚠ {{ $t('notify.no_image') }}</b-badge>
      </template>
      <template #cell(buttons)="{ item }">
        <b-button @click="onView(item)" v-b-tooltip.hover.topright="$t(`transaction.contact_withdraw[0]`)">
          {{ "View" }}
        </b-button>
      </template>
      <template #bottom-row v-if="items.length > 0 && !!localParams.action">
        <td colspan="1" class="font-weight-bold">{{ $t('general.total') }}</td>
        <td colspan="2"></td>
        <td colspan="1" class="font-weight-bold text-right">{{ trans.page_amount }} / {{ trans.total_amount }}</td>
        <td colspan="6"></td>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getList" :total="total" class="card-footer" />

    <b-modal
      lazy
      centered
      id="confirm"
      :title="confirmTarget ? $t(`user.approve_verification[1]`) : ''"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="w-50"
      :busy="isLoading"
      @hidden="hideConfirm">
      <b-form v-if="confirmTarget" id="approve-withdraw-form">
        <b-row cols="1" cols-xl="2">
          <b-col class="pr-xl-4">
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.user_id') }}:</span><span class="ml-3">{{ confirmTarget.user_id }}</span>
            </p>

            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('general.email') }}:</span><span class="ml-3">{{ confirmTarget.email }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.first_name') }}:</span><span class="ml-3">{{ confirmTarget.first_name }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.last_name') }}:</span><span class="ml-3">{{ confirmTarget.last_name }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.dob') }}:</span><span class="ml-3">{{ confirmTarget.dob }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.country') }}:</span><span class="ml-3">{{ confirmTarget.country }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.street') }}:</span><span class="ml-3">{{ confirmTarget.street }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.city') }}:</span><span class="ml-3">{{ confirmTarget.city }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.postcode') }}:</span><span class="ml-3">{{ confirmTarget.postcode }}</span>
            </p>
            <p class="mt-2 mb-0">
              <span class="text-sm">{{ $t('user.occupation') }}:</span><span class="ml-3">{{ confirmTarget.occupation }}</span>
            </p>
          </b-col>
          <b-col class="pr-xl-4">
            <p class="mt-2 mb-4">
              <span class="text-sm">{{ $t('user.proof_of_id') }}:</span>
              <span class="ml-3">
                <a v-if="confirmTarget.photo_id" :href="`${confirmTarget.photo_id}`" target="_blank" v-b-tooltip.right="'Click to open in new tab'">
                  <b-img-lazy :src="confirmTarget.photo_id" fluid rounded style="max-height: 6rem" class="hover-image" />
                </a>
                <b-badge v-else variant="danger" class="font-weight-bold"> ⚠ {{ $t('notify.no_image') }}</b-badge>
              </span>
            </p>
            <p class="mt-2 mb-4">
              <span class="text-sm">{{ $t('general.status') }}:</span>
              <span class="ml-3">
                <UserVerificationStatus :value="confirmTarget.status.toString()" />
              </span>
            </p>
          </b-col>
        </b-row>
        <b-form-group class="mt-2 mb-0 mx-0 p-0">
          <template #label> {{ $tc('general.comment', 2) }}<span v-if="confirmTarget.isReject" class="ml-1 text-danger">*</span> </template>
          <b-form-textarea v-model="confirmTarget['comments']" :required="confirmTarget.isReject ? true : false" :placeholder="$t('input.enter_reason')" />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ hide }">
        <b-button variant="outline-secondary" @click="hide" :disabled="isLoading">{{ isLoading ? $t('status.loading') : $t('action.cancel') }}</b-button>
        <b-button variant="outline-danger" @click="onReject" form="approve-withdraw-form" :disabled="isLoading">{{ $t('action.reject') }}</b-button>
        <b-button variant="outline-primary" @click="onApprove" form="approve-withdraw-form" :disabled="isLoading">{{ $t('action.approve') }}</b-button>
      </template>
    </b-modal>

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: detailTarget ? ' #' + detailTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      ><UserDetails v-if="detailTarget" :user="detailTarget" :key="detailTarget.id">
        <template #address="{ address }"><br /><AddressDetails :userStore="detailTarget.store_id" :address="address" /></template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import axios from '@/plugins/axios'
import { AwsUpdate } from '@/store/services/aws'
import { API_LIST } from '@/utils/consts'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatBytes, formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import UserVerificationStatus from '@/components/UserVerificationStatus.vue'
import { AWSSecurityWithURL } from '@/store/services/aws'


export default {
  components: {
    UserDetails,
    AddressDetails,
    UserVerificationStatus,
  },
  data() {
    const defaultParams = {
      action: null,
      status: null,
      user_id: null,
      username: null,
      start: null,
      end: null,
      page: 1,
      perPage: 10,
    }
    const params = {
      ...defaultParams,
      ...this.$route.params?.prev,
    }
    return {
      ranges: setupDateRanges(),
      maxDate: formatLocalDateTime(null, 'YYYY-MM-DD', true, true),
      actionList: ['reward', 'withdraw', 'pay'],
      isLoading: false,
      defaultParams: defaultParams,
      localParams: params,
      queryParams: { ...params },
      detailTarget: null,
      confirmTarget: null,
      images: [{ url: null, file: null, uploaded: false }],
      keysToExport: ['id', 'status', 'action', 'amount_auth', 'additional_info', 'username', 'photo_id', 'created_at'],
    }
  },
  computed: {
    ...mapGetters({ trans: 'request/userProfiles' }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    fields() {
      return [
        { key: 'user_id', label: this.$t('user.user_id'), sortable: true },
        { key: 'email', label: this.$t('general.email') },
        { key: 'first_name', label: this.$t('user.first_name') },
        { key: 'last_name', label: this.$t('user.last_name') },
        { key: 'dob', label: this.$t('user.dob') },
        { key: 'country', label: this.$t('user.country') },
        { key: 'street', label: this.$t('user.street') },
        { key: 'city', label: this.$t('user.city') },
        { key: 'postcode', label: this.$t('user.postcode') },
        { key: 'occupation', label: this.$t('user.occupation') },

        { key: 'photo_id', label: this.$t('user.proof_of_id'),
          formatter: (value, key, item) => {
            if (!item.loaded && value && value.includes(process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME)) {
              item.loaded = true
              AWSSecurityWithURL({ name: value.slice(value.lastIndexOf('/') + 1) }, 'rkings').then(({ blob }) => ( this.$set(item, 'photo_id', URL.createObjectURL(blob))))
            }
            return value
          },  },
        { key: 'created_at', label: this.$t('user.submission_date'), formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        { key: 'buttons', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'admin_user_id', label: this.$t('general.created_by'), formatter: (v) => v || 'SYSTEM' },
      ]
    },
    items: {
      get() {
        return this.$store.getters['request/userProfiles']?.data || []
      },
      set: (v) => v,
    },
    total: {
      get() {
        return this.$store.getters['request/userProfiles']?.total || 0
      },
      set: (v) => v,
    },
  },
  methods: {
    getList(arg) {
      this.isLoading = true
      const params = { ...this.queryParams, ...arg }
      this.localParams = params
      axios
        .get(API_LIST.get.userProfiles, { params: params })
        .then((res) => {
          this.items = res?.data?.data?.data || []
          this.total = res?.data?.data?.total || 0
          this.$store.dispatch('request/mutateState', { property: 'userProfiles', with: res?.data?.data })
        })
        .finally(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getList()
    },
    onReset() {
      this.queryParams = { ...this.defaultParams }
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.getList()
    },
    onView(obj) {
      this.confirmTarget = obj
      this.$bvModal.show('confirm')
    },
    onApprove() {
      this.isLoading = true
      axios
        .post(API_LIST.post.auditProfile, { profile_id: this.confirmTarget.id, status: 1, comments: this.confirmTarget.comments })
        .then((res) => {
          if (res?.data?.code == 0) {
            this.getList(this.localParams)
            notifySuccess(res, this.$t('user.approve_verification[3]'))
          } else {
            this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
          }
        })
        .catch((err) => notifyError(err, this.$t('user.approve_verification[4]')))
        .finally(() => { 
          this.hideConfirm();
          this.isLoading = false
        })
    },
    onReject() {
      this.isLoading = true
      axios
        .post(API_LIST.post.auditProfile, { profile_id: this.confirmTarget.id, status: -1, comments: this.confirmTarget.comments })
        .then((res) => {
          if (res?.data?.code == 0) {
            this.getList(this.localParams)
            notifySuccess(res, this.$t('user.reject_verification[3]'))
          } else {
            this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
          }
        })
        .catch((err) => notifyError(err, this.$t('user.reject_verification[4]')))
        .finally(() => { 
          this.hideConfirm();
          this.isLoading = false
        })
    },
    onContact(obj) {
      this.isLoading = true
      axios
        .post(API_LIST.post.auditWithdraw, { id: obj.id, action: 'contacted' })
        .then((res) => {
          if (res?.data?.code == 0) {
            this.getList(this.localParams)
            notifySuccess(res, this.$t('transaction.contact_withdraw[2]'))
          } else {
            this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
          }
        })
        .catch((err) => notifyError(err, this.$t('transaction.contact_withdraw[3]')))
        .finally(() => (this.isLoading = false))
    },
    async onSubmit() {
      if (this.confirmTarget?.id) {
        this.isLoading = true
        if (!this.confirmTarget.isReject && this.images[0].file && !this.images[0].uploaded) {
          await AwsUpdate('rkings', this.images[0].file, this.images[0].file?.name, this.images[0].file?.type).then((res) => {
            if (res?.Location) {
              this.$set(this.images[0], 'url', res?.Location)
              this.$set(this.images[0], 'uploaded', true)
            }
          })
        }
        if (this.images[0].url && this.images[0].uploaded) {
          const { id, isReject, comments, profile_id, user_id, user_wallet_id } = this.confirmTarget
          const payload = { id: id, action: isReject ? 'reject' : 'approve', comments: comments, profileData: { user_id: user_id, user_wallet_id: user_wallet_id, photo_id: this.images[0].url } }
          if (profile_id) {
            payload.profile_id = profile_id
          }
          axios
            .post(API_LIST.post.approveWithdraw, payload)
            .then((res) => {
              if (res?.data?.code == 0) {
                this.getList(this.localParams)
                notifySuccess(res, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[3]`))
                this.$bvModal.hide('confirm')
              } else {
                this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
              }
            })
            .catch((err) => notifyError(err, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[4]`)))
            .finally(() => (this.isLoading = false))
        } else {
          this.isLoading(false)
          this.$notify({ group: 'root', type: 'error', title: 'Error', text: 'No image uploaded' })
        }
      }
    },
    hideConfirm() {
      this.confirmTarget = null
      this.images = [{ url: null, file: null, uploaded: false }]
      this.$bvModal.hide('confirm')
    },
    viewUser(obj) {
      this.detailTarget = { ...obj }
      this.$bvModal.show('user-details')
    },
    formatWithGBP,
    formatBytes,
  },
  mounted() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>

<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queryParams['status']" class="mr-2 mb-2" style="width: 128px">
        <b-form-select-option v-for="(option, index) in ['canceled', 'failed', 'pending', 'succeeded', 'contacted', 'paid', 'transferred']" :key="index" :value="option">
          {{ $t('transaction.cash_status.' + option) }}
        </b-form-select-option>
        <b-form-select-option :value="null" class="text-placeholder">All Status</b-form-select-option>
      </b-form-select>

      <b-form-select v-model="queryParams['action']" class="mr-2 mb-2" style="width: 128px">
        <b-form-select-option v-for="(option, index) in actionList" :key="index" :value="option">
          {{ $t('transaction.cash_actions.' + option) }}
        </b-form-select-option>
        <b-form-select-option :value="null" class="text-placeholder">All Types</b-form-select-option>
      </b-form-select>

      <b-form-input v-model="queryParams['user_id']" type="search" :placeholder="$t('user.user_id')" style="width: 146px" class="mr-2 mb-2" @keyup.enter="onSearch" />

      <b-form-input v-model="queryParams['username']" type="search" :placeholder="$t('general.username')" style="width: 146px" class="mr-2 mb-2" @keyup.enter="onSearch" />

      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :show-dropdowns="true"
        :ranges="ranges"
        :max-date="maxDate"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px" />

      <button-group :onSearch="onSearch" :onReset="onReset" :onExport="onExport" showExport />
    </div>

    <b-table :fields="fields" :items="items" :busy="isLoading" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #cell(status)="{ value }">
        <CashTransStatus :value="value" />
      </template>
      <template #cell(comments)="{ value }">
        <tooltip-span :content="value" />
      </template>
      <template #cell(user)="{ value }">
        <span v-if="value" @click="viewUser(value)" class="action">{{ value.name ? value.name : '-' }}</span>
      </template>
      <!-- TODO: read security aws url for cash withdraw photo id -->
      <template #cell(profile)="{ value }">
        <a v-if="value && value.photo_id" :href="`${value.photo_id.indexOf('security') > -1 ? value.src_security : value.photo_id}`" target="_blank" v-b-tooltip.right="'Click to open in new tab'">
          <b-img-lazy v-if="value.photo_id.indexOf('security') < 0" :src="value.photo_id" fluid block rounded style="max-height: 4rem" class="hover-image" />
          <b-img-lazy v-else-if="value.src_security" :src="value.src_security" fluid block rounded style="max-height: 4rem" class="hover-image" />
        </a>
        <b-badge v-else variant="danger" class="font-weight-bold"> ⚠ {{ $t('notify.no_image') }}</b-badge>
      </template>
      <template #cell(is_id_verified)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('yes') : $t('no')" />
      </template>
      <template #cell(buttons)="{ item }">
        <!-- <b-button @click="onContact(item)" :disabled="item.action != 'withdraw' || item.status != 'pending'" variant="basic" v-b-tooltip.hover.topright="$t(`transaction.contact_withdraw[0]`)">
          {{ $t('transaction.contact_withdraw[1]') }}
        </b-button> -->
        <b-button
          @click="onApprove(item)"
          :disabled="item.status != 'pending'"
          variant="success"
          v-b-tooltip.hover.topleft="$t(`transaction.approve_withdraw[0]`)">
          <b-icon-check class="mr-1" />{{ $t('action.approve') }}
        </b-button>
        <b-button @click="onReject(item)" :disabled="item.action != 'withdraw' || item.status != 'pending'" variant="danger" v-b-tooltip.hover.topright="$t(`transaction.reject_withdraw[0]`)">
          {{ $t('action.reject') }}<b-icon-x class="ml-1" />
        </b-button>
      </template>
      <template #bottom-row v-if="items.length > 0 && !!localParams.action">
        <td colspan="1" class="font-weight-bold">{{ $t('general.total') }}</td>
        <td colspan="2"></td>
        <td colspan="1" class="font-weight-bold text-right">{{ trans.page_amount }} / {{ trans.total_amount }}</td>
        <td colspan="6"></td>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getList" :total="total" class="card-footer" />

    <b-modal
      lazy
      centered
      id="confirm"
      :title="confirmTarget ? $t(`transaction.${confirmTarget.isReject ? 'reject_' : 'approve_'}withdraw[1]`) : $t('action.confirm_action')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="w-50"
      :busy="isLoading"
      @hidden="hideConfirm">
      <b-form v-if="confirmTarget" @submit.prevent="onSubmit" id="approve-withdraw-form">
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $t('transaction.transaction_id') }}:</span><span class="ml-3">{{ confirmTarget.id }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $t('general.username') }}:</span><span class="ml-3">{{ confirmTarget.user ? confirmTarget.user.name : '-' }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $tc('general.amount', 1) }}:</span><span class="ml-3">{{ formatWithGBP(confirmTarget.amount_auth) }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $t('general.status') }}:</span>
          <span class="ml-3">
            <CashTransStatus :value="confirmTarget.status" />
            -->
            <CashTransStatus :value="confirmTarget.isReject ? 'failed' : 'succeeded'" />
          </span>
        </p>

        <b-table
          v-if="!confirmTarget.isReject && !isIdVerified(confirmTarget)"
          :fields="modalFields"
          :items="images"
          :busy="isLoading"
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          responsive
          head-variant="light"
          class="mt-2 mx-0 p-0">
          <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
          <template #cell(file)="{ item }">
            <b-form-file
              size="sm"
              accept="image/*"
              v-model="item['file']"
              @input="inputImage"
              :required="!confirmTarget.isReject && !item.uploaded"
              form="approve-withdraw-form"
              class="d-inline-block text-truncate"
              style="width: 100%" />
          </template>
          <template #cell(preview)="{ item }">
            <a v-if="item.url && !item.file" :href="item.src_security ? item.src_security : item.url" target="_blank" v-b-tooltip.right="'Click to open in new tab'">
              <b-img-lazy v-if="item.url.indexOf('security') < 0" :src="item.url" fluid block rounded style="max-height: 4rem" />
              <b-img-lazy v-else-if="item.src_security" :src="item.src_security" fluid block rounded style="max-height: 4rem" />
            </a>
            <b-img-lazy v-else :src="item.url ? item.url : ''" fluid block rounded style="max-height: 4rem" />
          </template>
          <template #cell(action)="{ item }">
            <b-button variant="outline-danger" size="xs" @click="initImage" :disabled="item.uploaded || !item.file"><b-icon-trash /></b-button>
          </template>
        </b-table>

        <p class="mt-4 mb-0">{{ $t(`transaction.${confirmTarget.isReject ? 'reject_' : 'approve_'}withdraw[2]`) }}</p>

        <b-form-group class="mt-2 mb-0 mx-0 p-0">
          <template #label> {{ $tc('general.comment', 2) }}<span v-if="confirmTarget.isReject" class="ml-1 text-danger">*</span> </template>
          <b-form-textarea v-model="confirmTarget['comments']" :required="confirmTarget.isReject ? true : false" :placeholder="$t('input.enter_reason')" />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ hide }">
        <b-button variant="outline-secondary" @click="hide" :disabled="isLoading">{{ isLoading ? $t('status.loading') : $t('action.cancel') }}</b-button>
        <b-button variant="outline-primary" type="submit" form="approve-withdraw-form" :disabled="isLoading">{{ $t('action.submit') }}</b-button>
      </template>
    </b-modal>

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: detailTarget ? ' #' + detailTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      ><UserDetails v-if="detailTarget" :user="detailTarget" :key="detailTarget.id">
        <template #address="{ address }"><br /><AddressDetails :userStore="detailTarget.store_id" :address="address" /></template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import axios from '@/plugins/axios'
import { AwsSecurityUpdate } from '@/store/services/aws'
import { API_LIST } from '@/utils/consts'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { base64Encode, exportAsExcel } from '@/utils/fileUtils'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatBytes, formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import CashTransStatus from '../components/CashTransStatus.vue'
import { AWSSecurityWithURL } from '@/store/services/aws'

export default {
  components: {
    UserDetails,
    AddressDetails,
    CashTransStatus,
  },
  data() {
    const defaultParams = {
      action: null,
      status: null,
      user_id: null,
      username: null,
      start: null,
      end: null,
      page: 1,
      perPage: 10,
    }
    const params = {
      ...defaultParams,
      ...this.$route.params?.prev,
    }
    return {
      ranges: setupDateRanges(),
      maxDate: formatLocalDateTime(null, 'YYYY-MM-DD', true, true),
      actionList: ['reward', 'withdraw', 'pay'],
      isLoading: false,
      defaultParams: defaultParams,
      localParams: params,
      queryParams: { ...params },
      detailTarget: null,
      confirmTarget: null,
      images: [{ url: null, file: null, uploaded: false }],
      keysToExport: ['id', 'status', 'action', 'amount_auth', 'comments', 'username', 'photo_id', 'created_at'],
    }
  },
  computed: {
    ...mapGetters({ trans: 'request/cashTransList' }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    fields() {
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        { key: 'action', label: this.$t('general.type'), sortable: true, formatter: (v) => this.$t('transaction.cash_actions.' + v) ?? v },
        { key: 'amount_auth', label: this.$tc('general.amount', 1), formatter: (v) => formatWithGBP(v), class: 'text-right', sortable: true },
        { key: 'comments', label: this.$tc('general.comment', 1) },
        { key: 'user', label: this.$t('general.username') },
        { key: 'profile', label: this.$t('user.proof_of_id'),
          formatter: (value, key, item) => {
            if (!item.loaded && value && value.photo_id && value.photo_id.includes(process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME)) {
              item.loaded = true
              AWSSecurityWithURL({ name: value.photo_id.slice(value.photo_id.lastIndexOf('/') + 1) }, 'rkings').then(({ blob }) => ( this.$set(item.profile, 'src_security', URL.createObjectURL(blob))))
            }
            return value
          },  },
        { key: 'is_id_verified', label: this.$t('user.is_id_verified'), formatter: (v, k, item) => this.isIdVerified(item) ? 1 : 0 },
        { key: 'buttons', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'admin_user_id', label: this.$t('general.created_by'), formatter: (v) => v || 'SYSTEM' },
      ]
    },
    modalFields() {
      return [
        { key: 'file', label: this.$t('user.proof_of_id'), tooltip: this.$t('image.replace_notice') },
        {
          key: 'file.size',
          label: this.$t('general.size') + ' (<600kb)',
          tooltip: this.$t('image.size_notice'),
          formatter: (v) => formatBytes(v),
          tdClass: (v) => (v >= 614400 ? 'text-danger' : ''),
        },
        { key: 'preview', label: this.$t('general.preview'),
          formatter: (value, key, item) => {
            if (!item.loaded && item.url && item.url.includes('security')) {
              item.loaded = true
              AWSSecurityWithURL({ name: item.url.slice(item.url.lastIndexOf('/') + 1) }, 'rkings').then(({ blob }) => ( this.$set(item, 'src_security', URL.createObjectURL(blob))))
            }
          }, },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
    items: {
      get() {
        return this.$store.getters['request/cashTransList']?.data || []
      },
      set: (v) => v,
    },
    total: {
      get() {
        return this.$store.getters['request/cashTransList']?.total || 0
      },
      set: (v) => v,
    },
  },
  methods: {
    getList(arg) {
      this.isLoading = true
      const params = { ...this.queryParams, ...arg }
      this.localParams = params
      axios
        .get(API_LIST.get.userCashTrans, { params: params })
        .then((res) => {
          this.items = res?.data?.data?.data || []
          this.total = res?.data?.data?.total || 0
          this.$store.dispatch('request/mutateState', { property: 'cashTransList', with: res?.data?.data })
        })
        .finally(() => (this.isLoading = false))
    },
    isIdVerified(item) {
      if(item?.profile)
        if(item.profile.status == 1)
          return true
      return false
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getList()
    },
    onReset() {
      this.queryParams = { ...this.defaultParams }
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.getList()
    },
    csvPreProcess(data) {
      for (const i in data) {
        const row = data[i]
        data[i].action = this.$t('transaction.cash_actions.' + row.action) ?? row.action
        data[i].amount_auth = formatWithGBP(row.amount_auth)
        data[i].username = row.user?.name ?? '-'
        data[i].photo_id = row.profile?.photo_id ?? '-'
        data[i].created_at = formatLocalDateTime(row.created_at)
        for (const j in data[i]) {
          if (this.keysToExport.indexOf(j) == -1) {
            delete data[i][j]
          }
        }
      }
    },
    async onExport() {
      return exportAsExcel(
        this.$t('sidebar.cash_history') + '_' + formatUtcDateTime(undefined, 2, true),
        API_LIST.get.userCashTrans,
        { ...this.queryParams, page: 1, perPage: 1000 },
        this.total,
        this.csvPreProcess,
        this.keysToExport,
        [
          [
            this.$t('general.id'),
            this.$t('general.status'),
            this.$t('general.type'),
            this.$tc('general.amount', 1),
            this.$tc('general.comment', 1),
            this.$t('general.username'),
            this.$t('user.proof_of_id'),
            this.$t('general.created_at'),
          ],
        ],
        (bool) => (this.isLoading = bool)
      )
    },
    onApprove(obj) {
      this.confirmTarget = { ...obj, isReject: false }
      if (obj?.profile?.photo_id) {
        this.images = [{ url: obj.profile.photo_id, file: null, uploaded: true }]
      }
      this.$bvModal.show('confirm')
    },
    onReject(obj) {
      this.confirmTarget = { ...obj, isReject: true }
      this.$bvModal.show('confirm')
    },
    onContact(obj) {
      this.isLoading = true
      axios
        .post(API_LIST.post.auditWithdraw, { id: obj.id, action: 'contacted' })
        .then((res) => {
          if (res?.data?.code == 0) {
            this.getList(this.localParams)
            notifySuccess(res, this.$t('transaction.contact_withdraw[2]'))
          } else {
            this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
          }
        })
        .catch((err) => notifyError(err, this.$t('transaction.contact_withdraw[3]')))
        .finally(() => (this.isLoading = false))
    },
    async onSubmit() {
      if (this.confirmTarget?.id) {
        this.isLoading = true
        if (!this.confirmTarget.isReject && !this.isIdVerified(this.confirmTarget) && this.images[0].file && !this.images[0].uploaded) {
          await AwsSecurityUpdate('rkings', this.images[0].file, this.images[0].file?.name, this.images[0].file?.type).then((res) => {
            if (res?.Location) {
              this.$set(this.images[0], 'url', res?.Location)
              this.$set(this.images[0], 'uploaded', true)
            }
          })
        }
        if (this.confirmTarget.isReject || this.isIdVerified(this.confirmTarget) || (this.images[0].url && this.images[0].uploaded)) {
          const { id, isReject, comments, profile_id, user_id, user_wallet_id } = this.confirmTarget
          const payload = { id: id, action: isReject ? 'reject' : 'approve', comments: comments, profileData: { user_id: user_id, user_wallet_id: user_wallet_id, photo_id: this.images[0].url } }
          if (profile_id) {
            payload.profile_id = profile_id
          }
          axios
            .post(!this.confirmTarget.isReject && !this.isIdVerified(this.confirmTarget) ? API_LIST.post.approveWithdraw : API_LIST.post.auditWithdraw, payload)
            .then((res) => {
              if (res?.data?.code == 0) {
                this.getList(this.localParams)
                notifySuccess(res, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[3]`))
                this.$bvModal.hide('confirm')
              } else {
                this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
              }
            })
            .catch((err) => notifyError(err, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[4]`)))
            .finally(() => (this.isLoading = false))
        } else {
          this.isLoading = false
          this.$notify({ group: 'root', type: 'error', title: 'Error', text: 'No image uploaded' })
        }
      }
    },
    // onSubmit() {
    //   if (this.confirmTarget?.id) {
    //     this.isLoading = true
    //     const { id, isReject, comments } = this.confirmTarget
    //     axios
    //       .post(API_LIST.post.approveWithdraw, { id: id, action: isReject ? 'reject' : 'approve', comments: comments })
    //       .then((res) => {
    //         if (res?.data?.code == 0) {
    //           this.getList(this.localParams)
    //           notifySuccess(res, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[3]`))
    //           this.$bvModal.hide('confirm')
    //         } else {
    //           this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
    //         }
    //       })
    //       .catch((err) => notifyError(err, this.$t(`transaction.${isReject ? 'reject_' : 'approve_'}withdraw[4]`)))
    //       .finally(() => (this.isLoading = false))
    //   }
    // },
    hideConfirm() {
      this.confirmTarget = null
      this.images = [{ url: null, file: null, uploaded: false }]
    },
    inputImage(file) {
      if (file) {
        base64Encode(file).then((res) => {
          this.$set(this.images[0], 'url', res)
          this.$set(this.images[0], 'uploaded', false)
        })
      } else {
        this.initImage()
      }
    },
    initImage() {
      const existing = this.confirmTarget?.profile?.photo_id
      this.$set(this.images[0], 'url', existing ?? null)
      this.$set(this.images[0], 'uploaded', existing ? true : false)
      this.$set(this.images[0], 'file', null)
    },
    viewUser(obj) {
      this.detailTarget = { ...obj }
      this.$bvModal.show('user-details')
    },
    formatWithGBP,
    formatBytes,
  },
  mounted() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>

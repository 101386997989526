<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <UserList
      :fields="computedFields"
      :queries="queries"
      :getter="getUserList"
    >
      <template v-if="selfStore == 1 || allowMultiStores" #create>
        <b-button variant="basic" v-b-modal.user-create class="ml-2"><b-icon-plus class="mr-1" />{{ $t('user.create_user[0]') }}</b-button>
        <UserCreate :getList="getUserList" />
      </template>
      <template #name="{ user }">
        <span class="action" @click="viewDetails(user)">{{ user.name }}</span>
      </template>
      <template #credit="{ user }">
        <span class="action" @click="viewTransactions(user, 'credit')">{{ user.store_id == 1 ? user.balance_amount : $t('user.transactions') }}</span>
        <template v-if="user.store_id == 1">
          <span class="divider divider-vertical"></span>
          <span class="action" @click="viewBalance(user)">{{ $t('user.add_credit[0]') }}</span>
        </template>
      </template>
      <template #cash="{ user }">
        <span
          v-if="user.store_id == 1 && user.cash_wallet"
          v-b-tooltip.hover.top="$t('user.total_available_pending')"
          @click="viewTransactions(user, 'cash')"
          class="action"
        >
          {{ formatWithGBP(floatAddition(user.cash_wallet.amount, user.cash_wallet.pending_amount)) }} / {{ formatWithGBP(user.cash_wallet.amount) }} /
          {{ formatWithGBP(user.cash_wallet.pending_amount * -1) }}
        </span>
        <span v-else> - / - / -</span>
      </template>
      <template #is_active="{ user }">
        <b-badge
          v-if="user.store_id == 1"
          :variant="user.is_active ? 'success' : 'danger'"
          @click="viewActivation(user)"
          v-b-tooltip.hover.top="user.is_active ? $t('action.deactivate') : $t('action.activate')"
          class="font-weight-bold text-uppercase"
          style="cursor: pointer"
        >
          {{ user.is_active ? '✅ ' + $t('status.active') : '🚫 ' + $t('status.inactive') }}
        </b-badge>
        <bool-badge
          v-else
          :value="user.is_active"
          :text="user.is_active ? $t('status.active') : $t('status.inactive')"
          class="font-weight-bold text-uppercase"
        />
      </template>
      <template #profile="{ user }">
        <span class="action" @click="viewVerification(user)">{{ "Submitted" }}</span>
      </template>
      <template #actions="{ user }">
        <b-button @click="viewOrders(user)" v-b-tooltip.hover.topleft="$tc('user.view_orders', 2)"><b-icon-cart-check /></b-button>
        <b-button @click="viewEdit(user)" v-b-tooltip.hover.top="$t('action.edit')"><b-icon-pencil /></b-button>
        <b-button v-if="user.store_id == 1" @click="viewPass(user)" v-b-tooltip.hover.topright="$t('auth.reset_password')"><b-icon-shield-lock /></b-button>
        <b-button
          v-if="hasPermission(152)"
          @click="viewAssignAffiliate(user)"
          :disabled="user.is_affiliate == 1"
          v-b-tooltip.hover.top="$t('action.assign_affiliate')"
          ><b-icon-people
        /></b-button>
      </template>
    </UserList>

    <UserTransactions id="user-trans" :userDetails.sync="transTarget" />
    <UserAddCredit id="user-add-credit" :userDetails.sync="addCreditTarget" :getList="getUserList" />
    <UserOrders id="user-orders" :userDetails.sync="ordersTarget" />
    <UserPassword id="user-password" :userDetails.sync="pwdTarget" />
    <UserActivation id="user-activation" :userDetails.sync="activateTarget" :getList="getUserList" />
    <UserAssignAffiliate id="user-assign-affiliate" :userDetails.sync="assignAffiliateTarget" :getList="getUserList" />
    <UserKycDetails id="user-kyc-details" :userDetails.sync="kycTarget" />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="`${isChangeable ? $t('action.edit') : ''} ` + $t('user.user_details', { id: detailTarget ? ' #' + detailTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      @hidden="hideModal"
    >
      <form id="user-details-form" @submit.prevent="submitDetails">
        <UserDetails v-if="detailTarget" :user="detailTarget">
          <template #phone="{ val }">
            <div :class="`mt-3 ${isChangeable ? 'd-flex align-items-center' : ''}`">
              <span class="font-weight-normal text-sm">{{ $t('user.phone') }}</span
              ><span class="ml-1 text-danger">*</span>
              <b-form-input v-if="isChangeable" v-model="detailsForm.profile.phone_num" required class="ml-3 w-50 d-inline-block" style="font-size: 0.85rem" />
              <span v-else class="ml-3 font-weight-light">{{ val ? val : $t('no_data') }}</span>
            </div>
          </template>
          <template #address="{ address }">
            <br />
            <AddressDetails
              v-if="isChangeable"
              :key="`address-${address ? address.id : 'nodata'}`"
              :form="detailsForm.address"
              :address="address"
              :userStore="detailTarget.store_id"
            />
            <AddressDetails v-else :key="`address-details-${address ? address.id : 'nodata'}`" :address="address" :userStore="detailTarget.store_id" />
          </template>
        </UserDetails>
        <br />
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">{{ $t('action.close') }}</b-button>
        <b-button v-if="isChangeable" variant="outline-primary" type="submit" form="user-details-form">{{ $t('action.submit') }}</b-button>
      </template>
    </b-modal>
  </card>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserActivation from '@/components/Users/UserActivation.vue'
import UserAssignAffiliate from '@/components/Users/UserAssignAffiliate.vue'
import UserAddCredit from '@/components/Users/UserAddCredit.vue'
import UserCreate from '@/components/Users/UserCreate.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import UserKycDetails from '@/components/Users/UserKycDetails.vue'
import UserList from '@/components/Users/UserList.vue'
import UserOrders from '@/components/Users/UserOrders.vue'
import UserPassword from '@/components/Users/UserPassword.vue'
import UserTransactions from '@/components/Users/UserTransactions.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatAddition, formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    routerMixin, // hasPermission
  ],
  components: {
    UserList,
    UserCreate,
    UserPassword,
    UserDetails,
    UserKycDetails,
    AddressDetails,
    UserAddCredit,
    UserTransactions,
    UserOrders,
    UserActivation,
    UserAssignAffiliate,
  },
  data() {
    return {
      transTarget: null,
      addCreditTarget: null,
      ordersTarget: null,
      pwdTarget: null,
      activateTarget: null,
      assignAffiliateTarget: null,
      detailTarget: null,
      kycTarget: null,
      queries: {
        store_id: null,
        name: null,
        is_active: null,
        is_affiliate: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      isChangeable: false,
      detailsForm: {
        profile: { phone_num: '' },
        address: {
          id: null,
          address_type: 'billing',
          first_name: '',
          last_name: '',
          street: '',
          street_2: '',
          city: '',
          phone: '',
          county: '',
          postcode: '',
          country: '',
          company: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters({ users: 'request/userList', userDetails: 'request/userDetails', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    computedFields() {
      const baseFields = [
        { key: 'id', label: this.$tc('user.user_id'), sortable: true },
        { key: 'name', label: this.$t('general.username'), stickyColumn: true },
        { key: 'display_name', label: this.$t('user.display_name') },
        { key: 'store_id', label: this.$tc('general.store', 1) },
        {
          key: 'balance_amount',
          label: this.$t('user.credit_balance'),
          thClass: 'text-center',
          tdClass: (v, k, i) => (i.store_id == 1 ? 'text-right' : 'text-center'),
        },
        {
          key: 'cash_wallet',
          label: this.$t('user.cash_balance'),
          thClass: 'text-center',
          tdClass: 'text-center',
          tooltip: this.$t('user.total_available_pending'),
        },
        {
          key: 'profile',
          label: this.$t('user.verification_level'),
          class: 'text-center',
          tdClass: 'text-uppercase',
        },
        {
          key: 'is_active',
          label: this.$t('general.status'),
          class: 'text-center',
          tdClass: 'text-uppercase',
          tooltip: this.$t('notify.click_for', { name: this.$t('user.activate_acc') }),
        },
        { key: 'actions', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'created_at', label: this.$t('user.registered_at'), sortable: true, formatter: v => this.formatLocalDateTime(v) },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (v ? (v.name ? v.name : v.id) : i.admin_user_id) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: v => this.formatLocalDateTime(v) },
      ]

      if (this.hasPermission(152)) {
        baseFields.splice(7, 0, { key: 'is_affiliate', label: this.$t('affiliate.is_referrer'), sortable: true, class: 'text-center' })
      }

      return baseFields
    },
  },
  methods: {
    getUserList(callback) {
      const params = {}
      for (const key in this.queries) {
        if (this.queries[key] || typeof this.queries[key] == 'number') {
          params[key] = this.queries[key]
        }
      }
      this.$store.dispatch('request/getUsers', params).then(() => {
        if (callback) {
          callback()
        }
      })
    },
    viewDetails(obj) {
      this.detailTarget = obj
      this.$bvModal.show('user-details')
    },
    viewVerification(obj) {
      this.kycTarget = obj
      this.$bvModal.show('user-kyc-details')
    },
    viewBalance(obj) {
      this.addCreditTarget = obj
      this.$bvModal.show('user-add-credit')
    },
    viewTransactions(obj, type) {
      this.transTarget = { ...obj, isCash: type == 'cash' }
      this.$bvModal.show('user-trans')
    },
    viewOrders(obj) {
      this.ordersTarget = obj
      this.$bvModal.show('user-orders')
    },
    viewEdit(obj) {
      this.detailTarget = obj
      this.isChangeable = true
      this.$bvModal.show('user-details')
    },
    viewPass(obj) {
      this.pwdTarget = obj
      this.$bvModal.show('user-password')
    },
    viewAssignAffiliate(obj) {
      this.assignAffiliateTarget = obj
      this.$bvModal.show('user-assign-affiliate')
    },
    viewActivation(obj) {
      this.activateTarget = obj
      this.$bvModal.show('user-activation')
    },
    hideModal() {
      this.transTarget = null
      this.addCreditTarget = null
      this.ordersTarget = null
      this.pwdTarget = null
      this.activateTarget = null
      this.assignAffiliateTarget = null
      this.detailTarget = null
      this.kycTarget = null
      this.resetDetailsForm()
      this.isChangeable = false
    },
    resetDetailsForm() {
      this.detailsForm = {
        profile: { phone_num: '' },
        address: {
          id: null,
          address_type: 'billing',
          first_name: '',
          last_name: '',
          street: '',
          street_2: '',
          city: '',
          phone: '',
          county: '',
          postcode: '',
          country: '',
          company: '',
        },
      }
    },
    submitDetails() {
      const params = {
        ...this.detailsForm,
        user_id: this.detailTarget.id,
        successMsg: this.$t('user.update_user_info[0]'),
        errorMsg: this.$t('user.update_user_info[1]'),
      }
      this.$store.dispatch('request/updateUserDetails', params).then(res => {
        if (res.status === 200) {
          const tmp_address = { ...this.userDetails.addresses[0], ...this.detailsForm.address }
          const tmp_profile = { ...this.userDetails.profile, phone_num: this.detailsForm.profile.phone_num }
          this.users.data.find(x => x.id === this.detailTarget.id).addresses[0] = tmp_address
          this.users.data.find(x => x.id === this.detailTarget.id).profile = tmp_profile
          this.userDetails.addresses[0] = tmp_address
          this.userDetails.profile = tmp_profile
          this.detailTarget.addresses[0] = tmp_address
          this.detailTarget.profile = tmp_profile
          this.isChangeable = false
        }
      })
    },
    formatLocalDateTime,
    formatWithGBP,
    floatAddition,
  },
  watch: {
    userDetails(newVal, oldVal) {
      if (newVal && newVal !== oldVal && newVal.id === this.detailTarget.id) {
        this.detailsForm.profile.phone_num = newVal.profile?.phone_num ?? ''
      } else {
        this.detailsForm.profile.phone_num = ''
      }
    },
  },
  mounted() {
    this.queries.store_id = this.allowMultiStores ? null : this.selfStore
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: max-content;
  min-width: 45%;
  max-width: 75%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 75%;
    max-width: 90%;
  }
}
</style>

<template>
  <b-modal
    lazy
    centered
    id="user-kyc-details"
    :title=" $t('sidebar.verification_review') + (userDetails ? ' #' + userDetails.id : '')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="pt-0 min-h-32"
    hide-footer
    @shown="onShown"
    @hidden="onHidden">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="userDetails">
      <b-row cols="1" cols-xl="2" v-if="profile" class="mb-4">
        <b-col class="pr-xl-4">
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.user_id') }}:</span><span class="ml-3">{{ profile.user_id }}</span>
          </p>

          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('general.email') }}:</span><span class="ml-3">{{ profile.email }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.first_name') }}:</span><span class="ml-3">{{ profile.first_name }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.last_name') }}:</span><span class="ml-3">{{ profile.last_name }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.dob') }}:</span><span class="ml-3">{{ profile.dob }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.country') }}:</span><span class="ml-3">{{ profile.country }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.street') }}:</span><span class="ml-3">{{ profile.street }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.city') }}:</span><span class="ml-3">{{ profile.city }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.postcode') }}:</span><span class="ml-3">{{ profile.postcode }}</span>
          </p>
          <p class="mt-2 mb-0">
            <span class="text-sm">{{ $t('user.occupation') }}:</span><span class="ml-3">{{ profile.occupation }}</span>
          </p>
        </b-col>
        <b-col class="pr-xl-4">
          <p class="mt-2 mb-4">
            <span class="text-sm">{{ $t('user.proof_of_id') }}:</span>
            <span class="ml-3">
              <a v-if="profile.photo_id" :href="`${profile.photo_id}`" target="_blank" v-b-tooltip.right="'Click to open in new tab'">
                <b-img-lazy :src="profile.photo_id" fluid rounded style="max-height: 6rem" class="hover-image" />
              </a>
              <b-badge v-else variant="danger" class="font-weight-bold"> ⚠ {{ $t('notify.no_image') }}</b-badge>
            </span>
          </p>

          <p class="mt-2 mb-4">
            <span class="text-sm">{{ $t('general.status') }}:</span>
            <span class="ml-3">
              <UserVerificationStatus :value="profile.status.toString()" />
            </span>
          </p>
        </b-col>
      </b-row>

      <b-row cols="1" cols-xl="2" v-if="!profile&&!isLoading" class="min-h-32 justify-content-center align-items-center text-center">
        {{ $t('no_data') }}
      </b-row>

    </template>
  </b-modal>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatAddition, formatWithGBP } from '@/utils/numberUtils'
import UserVerificationStatus from '@/components/UserVerificationStatus.vue'
import { AWSSecurityWithURL } from '@/store/services/aws'
import axios from '../../plugins/axios'

export default {
  name: 'UserKycDetails',
  mixins: [utilsMixin],
  props: { userDetails: Object },
  components: {
    UserVerificationStatus,
  },
  data() {
    return {
      isLoading: true,
      profile: null
    }
  },
  methods: {
    getUserDetails(user_id) {
      this.isLoading = true
      axios
        .get(API_LIST.get.userProfiles, { params: { user_id } })
        .then(res => {
          if (res?.data?.data?.data?.length>0) {
            this.profile = res.data.data.data[0]
            const photo_id = res.data.data.data[0].photo_id
            AWSSecurityWithURL({ name: photo_id.slice(photo_id.lastIndexOf('/') + 1) }, 'rkings').then(({ blob }) => ( this.profile.photo_id = URL.createObjectURL(blob)))
          }else{
            this.profile = null
          }
        })
        .finally(() => (this.isLoading = false))
    },
    getUserId() {
      return this.isAffiliate ? this.user.user_id : this.user.id
    },
    onShown() {
      if (this.userDetails?.id) {
        this.getUserDetails(this.userDetails.id)
      }
    },
    onHidden(evt) {
      this.$emit('update:userDetails', null)
    },
  },
}
</script>
<style>
.min-h-32 {
  min-height: 8rem;
}
</style>

<template>
  <b-badge pill :variant="{ 0: 'warning', 1: 'success', '-1': 'danger' }[value]">
    {{ $t(`user.verification_status.${value}`) }}
  </b-badge>
</template>
<script>
export default {
  name: 'UserVerificationStatus',
  props: {
    value: String,
  },
}
</script>
